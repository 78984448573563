p {
  color: #000;
  /* font-family: "SF Pro"; */
  font-size: 16px;
  font-style: normal;
  font-weight: 274;
  line-height: 26px; /* 162.5% */
}

h1 {
  color: #000;
  /* font-family: "SF Pro"; */
  font-size: 32px;
  font-style: normal;
  font-weight: 590;
  line-height: 50px; /* 156.25% */
}
